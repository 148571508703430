import {
  buildUrlPrefix,
  getCategoryRoute as getShopwareCategoryRoute,
  getProductRoute as getShopwareProductRoute,
} from '@shopware-pwa/helpers-next'
import type { Schemas } from '#shopware'

export function useShopRouter() {
  const localePath = useLocalePath()
  const urlResolver = useUrlResolver()
  const urlPrefix = urlResolver.getUrlPrefix()
  const { localeProperties } = useI18n()

  const getProductRoute = (product: Schemas['Product']) => {
    return localePath(
      buildUrlPrefix(getShopwareProductRoute(product), urlPrefix).path
    )
  }

  const getCategoryRoute = (category: Schemas['Category']) => {
    const seoUrls = category.seoUrls ?? []
    if (seoUrls.length) {
      const { languageId } = localeProperties.value
      const translatedSeoUrls = seoUrls.filter(
        (seoUrl) => seoUrl.languageId === languageId
      )
      const seoUrl = translatedSeoUrls.length
        ? translatedSeoUrls[translatedSeoUrls.length - 1].seoPathInfo
        : null
      return localePath(
        buildUrlPrefix(
          getShopwareCategoryRoute({
            ...category,
            seoUrls: seoUrl ? [{ seoPathInfo: seoUrl }] : category.seoUrls,
          }),
          urlPrefix
        ).path
      )
    }
    return localePath(
      buildUrlPrefix(
        getShopwareCategoryRoute({
          ...category,
          seoUrls: category.seoUrls,
        }),
        urlPrefix
      ).path
    )
  }

  const getShopRoute = (url: string) => {
    return localePath(buildUrlPrefix(url, urlPrefix).path)
  }

  return {
    getProductRoute,
    getCategoryRoute,
    getShopRoute,
  }
}
